// @ts-nocheck
/* eslint-disable no-param-reassign */
import Vue from 'vue';
import axios from 'axios';
import * as cookie from '../../helpers/helpers';
import { makeToast, isPORefMandatory, orderURLs } from '../../helpers/helpers';
import { countItem } from '../../components/cart/helpers';
import { doAdobe } from '../../helpers/adobe';
import { updateOrderDetails } from '../../components/OrderDetails/OrderDetailsStore';
import { orderRefNameDefault } from '../../constants';
import { redirectOnAuthFail } from '../../components/common/Auth';

/** ******************************
 * helpers - action names
 ****************************** */
export const updateGroupByEtd = '@cart/actions/update-group-by-etd';
export const initialiseCart = '@cart/actions/update-cart';
export const updateEtds = '@cart/actions/update-etds';
export const updateMerch = '@cart/actions/update-merch';
export const updateAssembly = '@cart/actions/update-assembly';
export const updateSortBy = '@cart/actions/update-sort-by';
export const updateFailed = '@cart/actions/updateFailed';
export const makeDirty = '@cart/actions/makeDirty';
export const cartLoadingError = '@cart/actions/cartLoadingError';
export const etdLoadingError = '@cart/actions/etdLoadingError';
export const deleteEntireCart = '@cart/actions/deleteEntireCart';
export const addCoupon = '@cart/actions/addCoupon';
export const removeCoupon = '@cart/actions/removeCoupon';
export const setPostcode = '@cart/actions/setPostcode';
export const clearCouponFieldError = '@cart/actions/clearCouponFieldError';
export const revertOrderToEdit = '@cart/actions/revertOrderToEdit';
export const updateClicked = '@cart/actions/continue/update';
export const skipNext = '@cart/actions/skipNext';

/** ******************************
 * helpers - getter names
 ****************************** */
export const orderid = '@cart/getters/orderid';
export const orderURL = '@cart/getters/orderURL';
export const groupCartByEtd = '@cart/getters/groupCartByEtd';
export const assemblyOptionSelected = '@cart/getters/assemblyOptionSelected';
export const assemblyOptionDetails = '@cart/getters/assemblyOptionDetails';
export const fieldAwaitingUpdate = '@cart/getters/fieldAwaitingUpdate';
export const fieldUpdated = '@cart/getters/fieldUpdated';
export const fieldFailedUpdate = '@cart/getters/fieldFailedUpdate';
export const isDirty = '@cart/getters/isDirty';
export const glCodesList = '@cart/getters/glCodesList';
export const glCodesMode = '@cart/getters/glCodesMode';
export const glCodesHeading = '@cart/getters/glCodesHeading';
export const cartStatus = '@cart/getters/cartStatus';
export const orderStatus = '@cart/getters/orderStatus';
export const activeCoupons = '@cart/getters/activeCoupons';
export const couponMessage = '@cart/getters/couponMessage';
export const couponFreeItem = '@cart/getters/couponFreeItem';
export const couponFreeDelivery = '@cart/getters/couponFreeDelivery';
export const cartIssues = '@cart/getters/cartIssues';
export const interstitial = '@cart/getters/interstitial';
export const cartSnippets = '@cart/getters/cartSnippets';
export const cartWarnings = '@cart/getters/cartWarnings';
export const cartErrors = '@cart/getters/cartErrors';
export const cartInfos = '@cart/getters/cartInfos';
export const rejectInfo = '@cart/getters/rejectInfo';
export const cartMinimumOrder = '@cart/getters/cartMinimumOrder';
export const cartSOSC = '@cart/getters/cartSOSC';
export const cartFreight = '@cart/getters/cartFreight';
export const cartOPI = '@cart/getters/cartOPI';
export const cartApprovers = '@cart/getters/cartApprovers';
export const cartDeliveryGap = '@cart/getters/cartDeliveryGap';
export const deliveryGapinfo = '@cart/getters/deliveryGapinfo';
export const serverItemError = '@cart/getters/serverItemError';
export const cartForward = '@cart/getters/cartForward';
export const cartSchedule = '@cart/getters/cartSchedule';
export const cartAlternatives = '@cart/getters/cartAlternatives';
export const readOnly = '@cart/getters/readOnly';
export const canDelete = '@cart/getters/canDelete';
export const canCancel = '@cart/getters/canCancel';
export const canComplete = '@cart/getters/canComplete';
export const hasErrors = '@cart/getters/hasErrors';
export const minimumOrderError = '@cart/getters/minimumOrderError';
export const hasOPIError = '@cart/getters/hasOPIError';
export const updatePending = '@cart/getters/updatePending';
export const topN = '@cart/getters/topN';
export const postcode = '@cart/getters/postcode';
export const cartItemsSorted = '@cart/getters/cartItemsSorted';
export const cartCost = '@cart/getters/cartCost';
export const cartQuantity = '@cart/getters/cartQuantity';
export const hasAssemblyOption = '@cart/getters/hasAssemblyOption';
export const singleUsePlastic = '@cart/getters/singleUsePlastic';
export const etdByProdcode = '@cart/getters/etdByProdcode';
export const prodcodesByEtd = '@cart/getters/prodcodesByEtd';
export const merch = '@cart/getters/merch';
export const getCustomerReference = '@cart/getters/getCustomerReference';
export const ccInfo = '@cart/getters/ccInfo';
export const loadingErrorMessage = '@cart/getters/loadingErrorMessage';
export const canRevert = '@cart/getters/canRevert';
export const isRefMandatory = '@cart/getters/isRefMandatory';
export const orderRefName = '@cart/getters/orderRefName';
export const cartContinueClicked = '@cart/getters/continue/clicked';
export const ist_eligible = '@cart/getters/ist_eligible';
export const cartEnvMessage = '@cart/getters/cartEnvMessage';

/** ******************************
 * helpers - utils
 ****************************** */
let cartUpdateTimeout = 0;
let cartUpdateNumber = 0;
const saveDelay = 1000;

const FIELD_UPDATE_PENDING = 'fieldUpdatePending';
const FIELD_UPDATE_SUCCESS = 'fieldUpdateSuccess';
const FIELD_UPDATE_FAILED = 'fieldUpdateFailed';

// users in both these streams have already had the interstitial
export const nxExtrasHeader = nxDatalayer?.global?.streams?.mini_inter
  && nxDatalayer?.global?.streams?.mini_cart
  ? 'apps,ca,adobe,u,env_message,cart_snippets'
  : 'apps,ca,charge_gap,adobe,u,interstitial,env_message,cart_snippets';

const valueByFieldId = (fieldId, obj, newVal) => {
  const fieldIdArr = fieldId.split(/_(.+)/);
  const itemId = fieldIdArr[0];
  const fieldName = fieldIdArr[1];
  const targetObj = itemId === ''
    ? obj
    : obj.items.find((el) => el.itemid === itemId);
  if (typeof newVal !== 'undefined') {
    targetObj[fieldName] = newVal;
  }
  return targetObj && targetObj[fieldName];
};

const specialIssues = {
  small_order_surcharge: 1,
  minimum_order_value: 1,
  order_exceeds_budget: 1,
  cp_item_acknowledgement: 1,
  custom_item_acknowledgement: 1,
  incorrect_status_for_mode: 1,
  item_added: 1,
  zone_freight_surcharge: 1,
};
const filterIssues = (issues, type) => issues.reduce((prev, curr) =>
  curr.type === type && !specialIssues[curr.code]
    ? [...prev, curr]
    : prev, []);

const buildItemsToUpdate = (itemID, quantity, costassign, note, action, hasAssembly) => {
  const base = {
    itemid: itemID,
    quantity,
    action
  };
  const processNote = typeof note !== 'undefined' ? { ...base, note } : base;
  const processGlCode = typeof costassign !== 'undefined' ? { ...processNote, costassign } : processNote;
  const processAssembly = typeof hasAssembly !== 'undefined' ? { ...processGlCode, has_assembly: hasAssembly } : processGlCode;
  return processAssembly;
};
const initialState = nxDatalayer.env.cart_endpoint === '' ? 'empty' : 'loading';

const getCartPayload = (
  customerReference,
  itemsToUpdate = [],
  cartItemById,
  itemsToDelete = []
): Response => {
  const toUpdate = itemsToUpdate.map((id) => {
    const { quantity, costassign, note, has_assembly: hasAssembly } = cartItemById(id);
    return buildItemsToUpdate(id, quantity, costassign, note, 'update', hasAssembly);
  });

  const toDelete = itemsToDelete.map((id) => ({
    itemid: id,
    action: 'delete'
  }));

  return {
    customerreference: customerReference,
    item_update: [...toUpdate, ...toDelete]
  };
};

const getLoadError = (payload) =>
  payload.issues
  && payload.issues[0]
  && payload.issues[0].shortcode === 'EXX'
  && payload.issues[0].message;

const standardCartUpdate = (st, payload, dispatch) => {
  st.cart = payload;
  st.cartItems = payload.items;
  st.cartIssues = payload.issues || [];
  st.coupons = payload.coupons || [];
  st.rejected = payload.rejected;
  st.rejected_by = payload.rejected_by;
  st.reason = payload.reason;
  st.approvers = payload.approvers;
  st.status = payload.status;
  st.url = payload.url;
  st.interstitial = payload.interstitial;
  st.cart_snippets = payload.cart_snippets;
  st.ist_eligible = payload.ist_eligible;
  if (payload?.u && payload?.cc) {
    st.isRefMandatory = isPORefMandatory(payload.u, payload.cc);
  }
  st.lastGoodApiResponse = JSON.stringify(payload);
  if (typeof payload.items === 'undefined') {
    const err = getLoadError(payload);
    st.cartStatus = 'error';
    st.loadingErrorMessage = err || st.loadingErrorMessage;
  } else if (payload.items.length === 0) {
    st.cartStatus = 'empty';
  } else {
    st.cartStatus = 'loaded';
  }
  // send update to orderDetails
  dispatch(updateOrderDetails, payload);
  st.updateInProgress = false;
  doAdobe(payload.adobe, 'ajax cart');
};
/** ******************************
 * state
 ****************************** */
const state = {
  cartStatus: initialState,
  status: '',
  cart: {},
  cartItems: [],
  cartIssues: [],
  updateRequired: false,
  isRefMandatory: false,
  itemsToUpdate: [],
  itemsToDelete: [],
  etds: '',
  dontGroupCartByEtd: cookie.getCookie('dontGroupCartByEtd') === 'true',
  updateInProgress: 0,
  sortBy: '',
  sortAscending: true,
  lastGoodApiResponse: '',
  fieldUpdateStatus: {},
  dirtyFields: [],
  coupons: [],
  couponMessage: {},
  topN: '',
  endecaInlineTop: '',
  endecaInlineFull: '',
  postcode: window.nxDatalayer.global.postcode,
  loadingErrorMessage: 'Unable to load order',
  continueClicked: false,
  rejected: false,
  rejected_by: '',
  reason: '',
  url: '',
  merch: '',
  interstitial: {},
  cart_snippets: {},
};

const getters = {
  [orderid]: (st) => st.cart.orderid,
  [orderURL]: (st) => orderURLs(st.url),
  [postcode]: (st) => st.postcode,
  [updatePending]: (st) => st.updateRequired || st.updateInProgress,
  [hasOPIError]: (st) => st.cart.opi.type === 'reject',
  [minimumOrderError]: (st, getters) =>
    getters[cartMinimumOrder] && getters[cartMinimumOrder].type === 'E',
  [hasErrors]: (st, getters) =>
    getters[minimumOrderError]
    || getters[hasOPIError]
    || getters[cartErrors].length > 0,
  [readOnly]: (st) => !st.cart.permissions.editable,
  [canDelete]: (st) => st.cart.permissions.deletable,
  [canCancel]: (st) => st.cart.permissions.cancel,
  [canComplete]: (st) => st.cart.permissions.complete,
  [canRevert]: (st) => st.cart.permissions.reclaim || st.cart.permissions.revert,
  [cartAlternatives]: (st) => st.cart.alternatives,
  [cartStatus]: (st) => st.cartStatus,
  [orderStatus]: (st) => st.status,
  [cartEnvMessage]: (st) => st.cart.env_message,
  cart: (st) => st.cart,
  cartItems: (st) => st.cartItems,
  [cartItemsSorted]: (st) => {
    if (st.sortBy === '') {
      return [...st.cartItems];
    }
    return [...st.cartItems].sort((itemA, itemB) => {
      function getSortValue(key, el) {
        if (key === 'totalCost') {
          return el.cost.base.mills;
        }
        if (key === 'itemCost') {
          return el.item_cost.base.mills;
        }
        if (key === 'stock') {
          return el.stock.quantity;
        }
        return el[key];
      }
      let v1;
      let v2;
      if (st.sortAscending) {
        v1 = getSortValue(st.sortBy, itemB);
        v2 = getSortValue(st.sortBy, itemA);
      } else {
        v2 = getSortValue(st.sortBy, itemB);
        v1 = getSortValue(st.sortBy, itemA);
      }
      if (v1 > v2) {
        return 1;
      }
      if (v2 > v1) {
        return -1;
      }
      return 0;
    });
  },
  cartItemById: (st) => (itemid) => st.cartItems.find((cartItem) => cartItem.itemid === itemid),
  [interstitial]: (st) => st.interstitial,
  [cartSnippets]: (st) => st.cart_snippets,
  [cartIssues]: (st) => st.cartIssues,
  [cartWarnings]: (st) => filterIssues(st.cartIssues, 'W'),
  [cartErrors]: (st) => filterIssues(st.cartIssues, 'E'),
  [cartInfos]: (st) => filterIssues(st.cartIssues, 'I'),
  [rejectInfo]: (st) => {
    const { rejected, rejected_by, reason } = st;
    return { rejected, rejected_by, reason };
  },
  [cartMinimumOrder]: (st) => st.cartIssues.find((el) => el.code === 'minimum_order_value'),
  [cartSOSC]: (st) => st.cartIssues.find((el) => el.code === 'small_order_surcharge'),
  [cartFreight]: (st) => st.cartIssues.find((el) => el.code === 'zone_freight_surcharge'),
  [cartOPI]: (st) => {
    const { reasons } = st.cart.opi;
    reasons.forEach((r) => {
      if (r.rule_info) {
        r.rule_info = r.rule_info.replace(/\n/g, '<br/>');
      }
    });
    return st.cart.opi;
  },
  [cartApprovers]: (st) => st.cart.approvers,
  [cartDeliveryGap]: (st) => st.cartIssues.find((el) => el.code === 'retail_surcharge'),
  [deliveryGapinfo]: (st) => st.cart.charge_gap,
  [serverItemError]: (st) => (itemId, field) =>
    st.cartIssues.find((el) => el.itemid === itemId && el.field === field),
  [cartCost]: (st) => st.cart.cost,
  [cartQuantity]: (st) => st.cartItems.reduce((acc, cartItem) => {
    return countItem(cartItem) + acc;
  }, 0),
  [getCustomerReference]: (st) => st.cart.customerreference || '',
  [merch]: (st) => st.merch,
  [prodcodesByEtd]: (st) => {
    const items = st.cartItems;
    const itemsByProdcode = items.reduce((acc, item) => {
      acc[item.prodcode] = item;
      return acc;
    }, {});
    return Object.keys(st.etds).reduce((acc, prodcode) => {
      let date;
      const item = itemsByProdcode[prodcode];
      if (!item) return acc;
      if (item.show_etd) {
        date = st.etds[prodcode].date || '';
      } else {
        date = item.etd_sort;
      }
      if (typeof acc[date] === 'undefined') {
        acc[date] = [prodcode];
      } else {
        acc[date].push(prodcode);
      }
      return acc;
    }, {});
  },
  [etdByProdcode]: (st) => (prodcode) => st.etds[prodcode] || {},
  [groupCartByEtd]: (st) => !st.dontGroupCartByEtd,
  [hasAssemblyOption]: (st) => (itemid) => {
    const item = st.cartItems.find((cartItem) => cartItem.itemid === itemid);
    if (typeof item.assembly_item === 'undefined' || Object.keys(item.assembly_item).length === 0) {
      return false;
    }
    return true;
  },
  [assemblyOptionSelected]: (st) => (itemid) => {
    const item = st.cartItems.find((cartItem) => cartItem.itemid === itemid);
    return item.has_assembly;
  },
  [assemblyOptionDetails]: (st) => (itemid) => {
    const item = st.cartItems.find((cartItem) => cartItem.itemid === itemid);
    return item.assembly_item;
  },
  [singleUsePlastic]: (st) => (itemid) => {
    const item = st.cartItems.find((cartItem) => cartItem.itemid === itemid);
    return item.single_use_plastic;
  },
  [fieldAwaitingUpdate]: (st) => (fieldId) =>
    st.fieldUpdateStatus[fieldId] === FIELD_UPDATE_PENDING,
  [fieldUpdated]: (st) => (fieldId) =>
    st.fieldUpdateStatus[fieldId] === FIELD_UPDATE_SUCCESS,
  [fieldFailedUpdate]: (st) => (fieldId) =>
    st.fieldUpdateStatus[fieldId] === FIELD_UPDATE_FAILED,
  [isDirty]: (st) => (itemId) => st.dirtyFields.includes(itemId),
  [isRefMandatory]: (st) => st.isRefMandatory,
  [glCodesList]: (st) => {
    const { ca } = st.cart;
    if (ca && ca.cas) {
      return ca.cas;
    }
    return [];
  },
  [glCodesMode]: (st) => {
    const { ca } = st.cart;
    if (ca && ca.mode === 'free') {
      return 'text';
    }
    if (ca && ca.mode === 'list') {
      return 'list';
    }
    if (ca && ca.mode === 'extended') {
      return 'extended';
    }
    return 'none';
  },
  [glCodesHeading]: (st) => {
    const { ca } = st.cart;
    if (ca && ca.description) {
      return ca.description;
    }
    return 'GL Code';
  },
  [activeCoupons]: (st) => st.coupons,
  [couponMessage]: (st) => st.couponMessage,
  [couponFreeItem]: (st) => {
    const matchingCoupon = st.coupons.find((el) => el.type === 'item');
    return matchingCoupon
      ? matchingCoupon.item
      : undefined;
  },
  [couponFreeDelivery]: (st) => st.coupons.find((el) => el.type === 'delivery'),
  [cartForward]: (st) => typeof st.cart.schedule === 'undefined'
    ? st.cart.forwarddate
    : undefined,
  [cartSchedule]: (st) => st.cart.schedule,
  [orderRefName]: (st) => st.cart.cc.referencename || orderRefNameDefault,
  [topN]: (st) => st.topN,
  [ccInfo]: (st) => st.cart.cc,
  [loadingErrorMessage]: (st) => st.loadingErrorMessage,
  [cartContinueClicked]: (st) => st.continueClicked,
  [ist_eligible]: (st) => st.ist_eligible,
};

const mutations = {
  [initialiseCart](st, payload) {
    // set here things like top ordered and endeca contents that we only want to
    // populate once. Otherwise we would need to do ETD, and potentially prices, again
    st.topN = payload.top_n ? payload.top_n : '';
    st.endecaInlineTop = '';
    st.endecaInlineFull = '';
    standardCartUpdate(st, payload, this.dispatch);
  },
  [updateClicked](st, clicked) {
    st.continueClicked = clicked;
  },
  [updateMerch](st, payload) {
    st.merch = payload;
  },
  [updateEtds](st, payload) {
    st.etds = payload;
  },
  [etdLoadingError](st) {
    makeToast({
      title: 'Delivery Date Failed',
      text: 'Estimated delivery dates are currently unavailable.',
      type: 'error',
      duration: -1,
    });
  },
  [updateGroupByEtd](st, payload) {
    st.dontGroupCartByEtd = !payload.value;
    cookie.setCookie('dontGroupCartByEtd', (!payload.value).toString());
  },
  [updateAssembly](st, payload) {
    const item = this.getters.cartItemById(payload.itemId);
    item.has_assembly = payload.value;
  },
  [updateSortBy](st, payload) {
    const key = payload.value;
    if (st.sortBy === key && st.sortAscending) {
      // second click
      st.sortAscending = false;
    } else if (st.sortBy === key && !st.sortAscending) {
      // third click
      st.sortAscending = true;
      st.sortBy = '';
    } else {
      // first click
      st.sortAscending = true;
      st.sortBy = key;
    }
  },
  [cartLoadingError](st) {
    st.cartStatus = 'error';
  },
  UPDATE_CUSTOMER_REFERENCE(st, payload) {
    Vue.set(st.cart, 'customerreference', payload.value);
  },
  SET_QUANTITY(st, payload) {
    const item = this.getters.cartItemById(payload.itemId);
    item.quantity = payload.quantity;
  },
  DELETE_ITEM(st, payload) {
    st.updateRequired = true;
    st.itemsToDelete.push(payload.value);
  },
  UPDATE_REQUIRED(st, payload) {
    if (!payload.field) return;
    const fieldRef = `${payload.itemId}_${payload.field}`;
    st.updateRequired = true;
    if (payload.itemId && !st.itemsToUpdate.includes(payload.itemId)) {
      st.itemsToUpdate.push(payload.itemId);
    }
    Vue.set(st.fieldUpdateStatus, fieldRef, FIELD_UPDATE_PENDING);
  },
  UPDATE_IN_PROGRESS(st, payload) {
    st.updateInProgress = payload.value;
  },
  UPDATE_COMPLETE(st, payload) {
    // check the field updates
    const toClear = [];
    Object.keys(st.fieldUpdateStatus).forEach((fieldId) => {
      if (st.fieldUpdateStatus[fieldId] !== FIELD_UPDATE_PENDING) return;
      const stateVal = valueByFieldId(fieldId, st.cart) || '';
      const newVal = valueByFieldId(fieldId, payload) || '';
      st.fieldUpdateStatus[fieldId] = FIELD_UPDATE_SUCCESS;
      toClear.push(fieldId);
    });
    // check the deletions
    st.itemsToDelete.forEach((itemId) => {
      const item = payload.items.find((el) => el.itemid === itemId);
      if (typeof item === 'undefined') {
        makeToast({
          title: 'Item deleted',
          text: 'Item successfully deleted.',
          type: 'success',
        });
      } else {
        makeToast({
          title: 'Item delete Failed',
          text: 'There was a problem with deleting an item. Please try again.',
          type: 'error',
          duration: -1,
        });
      }
    });
    standardCartUpdate(st, payload, this.dispatch);
    st.updateRequired = false;
    st.itemsToUpdate = [];
    st.itemsToDelete = [];
    cartUpdateNumber = 0;
    window.nx.addToCart.updateCart();
    // remember to remove any updated messages as these can be confusing when they're moving
    //  around and they can redisplay when rendered in a different spot
    window.setTimeout(() => {
      toClear.forEach((key) => {
        if (st.fieldUpdateStatus[key] === FIELD_UPDATE_SUCCESS) {
          Vue.delete(st.fieldUpdateStatus, key);
        }
      });
    }, 3000);
  },
  [updateFailed](st) {
    const lastGoodUpdate = JSON.parse(st.lastGoodApiResponse);
    Object.keys(st.fieldUpdateStatus).forEach((fieldId) => {
      const shouldBe = valueByFieldId(fieldId, lastGoodUpdate);
      valueByFieldId(fieldId, st.cart, shouldBe);
      st.fieldUpdateStatus[fieldId] = FIELD_UPDATE_FAILED;
    });
    st.updateRequired = false;
    st.updateInProgress = false;
    st.itemsToUpdate = [];
    st.itemsToDelete = [];
    cartUpdateNumber = 0;
    makeToast({
      title: 'Update Failed',
      text: 'There was a problem with saving your edits. Please try again.',
      type: 'error',
      duration: -1,
    });
  },
  UPDATE_FROM_COUPON(st, payload) {
    standardCartUpdate(st, payload, this.dispatch);
    const couponAction = payload.action;
    let couponMessage = {};
    if (couponAction && couponAction.type === 'coupon') {
      if (couponAction.status === 'fail') {
        couponMessage = {
          message: couponAction.message,
          code: '',
        };
      }
      if (couponAction.status === 'success' && couponAction.message === 'Removed') {
        makeToast({
          title: 'Coupon removed',
          text: 'Coupon successfully removed.',
          type: 'success',
          duration: -1,
        });
      }
      if (couponAction.status === 'success' && couponAction.message === 'Added') {
        couponMessage = {
          message: couponAction.message,
          code: couponAction.code,
        };
      }
      st.couponMessage = couponMessage;
    }
  },
  [clearCouponFieldError](st) {
    st.couponMessage = {};
  },
  [setPostcode]: (st, postcode) => {
    st.postcode = postcode;
  },
  [makeDirty]: (st, fieldId) => !st.dirtyFields.includes(fieldId) && st.dirtyFields.push(fieldId),
  [deleteEntireCart]: (st) => {
    st.cartStatus = 'empty';
  },
  [revertOrderToEdit]: (st, args) => {
    standardCartUpdate(st, args.payload, args.dispatch);
  },
  [skipNext]: (st, args) => {
    standardCartUpdate(st, args.payload, args.dispatch);
  }
};

const actions = {
  [revertOrderToEdit]({ commit, dispatch, getters }) {
    const urls = getters[orderURL];
    commit('UPDATE_IN_PROGRESS', { value: 'revertToEdit' });
    const uri = urls.revert;
    axios.post(uri, {}, {
      headers: {
        'X-NX-Checkout': 'pre-flight',
        'X-NX-Extras': nxExtrasHeader,
      }
    }).then((response) => {
      commit(revertOrderToEdit, { payload: response.data, dispatch });
    }).catch((error) => {
      redirectOnAuthFail(error);
      const msg = error?.response?.data || error;
      makeToast({
        title: 'Failed to edit',
        text: msg,
        type: 'error',
        duration: -1,
      });
      commit('UPDATE_IN_PROGRESS', { value: '' });
    });
  },
  [skipNext]: ({ commit, dispatch, getters }, skipnext) => {
    const urls = getters[orderURL];
    commit('UPDATE_IN_PROGRESS', { value: 'skip' });
    const uri = urls.skip;
    axios.post(uri, { skipnext }, {
      headers: {
        'X-NX-Checkout': 'pre-flight',
        'X-NX-Extras': nxExtrasHeader,
      }
    }).then((response) => {
      commit(skipNext, { payload: response.data, dispatch });
    }).catch((error) => {
      redirectOnAuthFail(error);
      const msg = error?.response?.data || error;
      makeToast({
        title: 'Failed to update',
        text: msg,
        type: 'error',
        duration: -1,
      });
      commit('UPDATE_IN_PROGRESS', { value: '' });
    });
  },
  [setPostcode]({ commit }, payload) {
    commit(setPostcode, payload);
  },
  [addCoupon]({ commit, getters }, payload) {
    const encoded = encodeURIComponent(payload.value);
    const urls = getters[orderURL];
    commit('UPDATE_IN_PROGRESS', { value: 'AddCoupon' });
    const uri = `${urls.coupon}/${encoded}`;
    axios
      .post(uri, {}, {
        headers: {
          'X-NX-Checkout': 'pre-flight',
          'X-NX-Extras': nxExtrasHeader,
        }
      })
      .then((response) => {
        commit('UPDATE_FROM_COUPON', response.data);
      })
      .catch((error) => {
        redirectOnAuthFail(error);
        makeToast({
          title: 'Failed to add coupon',
          text: 'There was a problem with adding the coupon.',
          type: 'error',
          duration: -1,
        });
        commit('UPDATE_IN_PROGRESS', { value: '' });
      });
  },
  [removeCoupon]({ commit, getters }, couponName) {
    const encoded = encodeURIComponent(couponName);
    const urls = getters[orderURL];
    const uri = `${urls.coupon}/${encoded}`;
    axios
      .delete(uri, {
        headers: {
          'X-NX-Checkout': 'pre-flight',
          'X-NX-Extras': nxExtrasHeader,
        }
      })
      .then((response) => {
        commit('UPDATE_FROM_COUPON', response.data);
      })
      .catch((error) => {
        redirectOnAuthFail(error);
        makeToast({
          title: 'Failed to remove coupon',
          text: 'There was a problem with removing the coupon.',
          type: 'error',
          duration: -1,
        });
      });
  },
  [clearCouponFieldError]({ commit }) {
    commit(clearCouponFieldError);
  },
  [cartLoadingError]({ commit }) {
    commit(cartLoadingError);
  },
  [initialiseCart]({ commit }, payload) {
    commit(initialiseCart, payload);
  },
  [updateClicked]({ commit }, clicked) {
    commit(updateClicked, clicked);
  },
  [updateMerch]({ commit }, payload) {
    commit(updateMerch, payload);
  },
  [updateEtds]({ commit }, payload) {
    commit(updateEtds, payload);
  },
  [etdLoadingError]({ commit }, payload) {
    commit(etdLoadingError, payload);
  },
  [updateGroupByEtd]({ commit }, value) {
    commit(updateGroupByEtd, value);
  },
  [updateAssembly]({ commit }, value) {
    commit(updateAssembly, value);
  },
  [updateSortBy]({ commit }, value) {
    commit(updateSortBy, value);
  },
  [makeDirty]({ commit }, value) {
    commit(makeDirty, value.fieldId);
  },
  [deleteEntireCart]({ commit, getters }) {
    const urls = getters[orderURL];
    axios
      .delete(urls.order, {
        headers: {
          'X-NX-Checkout': 'pre-flight',
          'X-NX-Extras': nxExtrasHeader,
        }
      })
      .then(() => {
        commit(deleteEntireCart);
        window.nx.addToCart.updateCart();
      })
      .catch((error) => {
        redirectOnAuthFail(error);
        commit(cartLoadingError);
      });
  },
  updateCustomerReference({ commit }, value) {
    commit('UPDATE_CUSTOMER_REFERENCE', value);
  },
  setQuantity({ commit }, value) {
    commit('SET_QUANTITY', value);
  },
  deleteItem({ commit }, value) {
    commit('DELETE_ITEM', value);
  },
  // we should do an update next time the user pauses. Start a timeout
  queueUpdate({ commit }, value) {
    if (value) {
      commit('UPDATE_REQUIRED', value);
    }
    if (cartUpdateTimeout) {
      // clear pending timeouts
      clearTimeout(cartUpdateTimeout);
    }
    if (cartUpdateNumber) {
      // ensure pending requests aren't processed, because we'll be sending another shortly
      cartUpdateNumber = 0;
    }
    const cb = function cb() {
      this.dispatch('sendUpdate');
    };
    cartUpdateTimeout = setTimeout(cb.bind(this), saveDelay);
  },
  // the user has paused their editing. Lets try to save
  sendUpdate({ commit, getters }) {
    const { itemsToUpdate, itemsToDelete, cart } = this.state.cartModule;
    const { customerreference } = cart;
    const { cartItemById } = this.getters;
    const urls = getters[orderURL];
    const payload = getCartPayload(
      customerreference,
      itemsToUpdate,
      cartItemById,
      itemsToDelete
    );
    cartUpdateNumber = new Date().getTime().toString();
    commit('UPDATE_IN_PROGRESS', { value: cartUpdateNumber });
    axios
      .post(urls.order, payload, {
        headers: {
          'X-NX-Checkout': 'pre-flight',
          'X-NX-Extras': nxExtrasHeader,
          'X-NX-Request-Id': cartUpdateNumber
        }
      })
      .then((response) => {
        if (cartUpdateNumber === response.headers['x-nx-request-id']) {
          commit('UPDATE_COMPLETE', response.data);
        }
      })
      .catch((error) => {
        redirectOnAuthFail(error);
        commit(updateFailed);
      });
  }
};

const cartModule = {
  state,
  mutations,
  actions,
  getters
};
export default cartModule;
